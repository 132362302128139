import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import styled from 'styled-components'
import ReactStars from 'react-stars'
import { COLOR_CONSTANTS, colors } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import carouselDoubleArrowRightBlueIcon from 'static/images/home/double_arrow_right_blue.svg'
import carouselThumbUpIcon from 'static/images/home/thumb_up.svg'
import carouselHeartBlueIcon from 'static/images/home/heart_blue.svg'
import iconArrowLeft from 'static/images/arrow_left.svg'
import iconArrowRight from 'static/images/arrow_right.svg'

const StyledSlideWrapper = styled(Flex)`
  border-radius: 24px;
`

const StyledAuthorWrapper = styled(Flex)`
  width: 140px;
  height: 140px;
  border-radius: 16px;
`

const ArrowWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  ${({ hasBorder }) => (hasBorder ? 'border: 1px solid #b6cef0;' : '')}
  border-radius: 30px;
  width: 48px;
  height: 48px;
  cursor: pointer;
`

const StyledUpperCaseText = styled(Text)`
  text-transform: uppercase;
`

const StyledReadStoryWrapper = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledThumbUpImage = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  top: 55px;
`

const StyledHeartBlueImage = styled(LocalImage)`
  position: absolute;
  z-index: 1;
  top: 260px;
  right: -12px;
  width: 48px;
  height: 48px;
`

const ReviewerImage = styled(LocalImage)`
  border-radius: 24px;
`

const settings = {
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
}

const CarouselReview = ({ reviews }) => {
  const sliderRef = useRef(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [shuffledReviews, setShuffledReviews] = useState([])

  useEffect(() => {
    setShuffledReviews(reviews.sort((a, b) => 0.5 - Math.random()))
  }, [])

  const reviewsLength = reviews.length

  const handleClickBack = () => {
    sliderRef.current.slickPrev()
  }

  const handleClickForward = () => {
    sliderRef.current.slickNext()
  }

  return (
    <Flex px="m">
      <Box width="100%" maxWidth={CONTAINER_MAX_WIDTH} mx="auto" position="relative">
        <StyledThumbUpImage src={carouselThumbUpIcon} left={{ mobile: '-75px', tablet: '-60px' }} />
        <StyledHeartBlueImage src={carouselHeartBlueIcon} />
        <Slider
          ref={sliderRef}
          {...settings}
          afterChange={(index) => {
            setActiveSlide(index)
          }}
        >
          {shuffledReviews.map(({ link, description, author: { name, position, logo }, count }, index) => {
            return (
              <StyledSlideWrapper p={{ mobile: 'm', tablet: 'xl' }} key={index} bg={COLOR_CONSTANTS.WHITE}>
                <Flex px="s" flexDirection="column" alignItems="center" flexWrap="wrap">
                  <Box height={{ mobile: 'auto', tablet: '160px' }} width="100%">
                    <Text
                      fontSize={{ mobile: 'l', mobileLarge: 'xl', tablet: 'xxl' }}
                      color={COLOR_CONSTANTS.DENIM}
                      textAlign="left"
                    >
                      “{description}”
                    </Text>
                  </Box>
                  <Flex
                    mt="l"
                    justifyContent={{ mobile: 'flex-start', tablet: 'space-between' }}
                    width="100%"
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                  >
                    <Flex>
                      <StyledAuthorWrapper
                        width={{ mobile: '80px', tablet: '140px' }}
                        height={{ mobile: '80px', tablet: '140px' }}
                      >
                        <ReviewerImage src={logo} width="100%" height="100%" />
                      </StyledAuthorWrapper>
                      <Flex ml={{ mobile: 's', tablet: 'l' }} flexDirection="column" justifyContent="space-between">
                        <Flex flexDirection="column">
                          <StyledUpperCaseText textAlign="left" fontSize="l" fontWeight="bold" color="secondaryText">
                            {name}
                          </StyledUpperCaseText>
                          <Text textAlign="left" color="secondaryText">
                            {position}
                          </Text>
                          <ReactStars
                            count={5}
                            size={24}
                            color2={colors.primary}
                            color1="rgba(0, 99, 227, 0.2)"
                            value={count}
                            edit={false}
                            half={false}
                          />
                        </Flex>
                        <StyledReadStoryWrapper as="a" href={link} textAlign="left" target="_blank">
                          <Text fontSize="l" fontWeight="medium" color="primary" as="span">
                            READ ON G2
                          </Text>
                          <LocalImage mt="-2px" ml="s" src={carouselDoubleArrowRightBlueIcon} />
                        </StyledReadStoryWrapper>
                      </Flex>
                    </Flex>
                    <Flex alignItems="flex-end" mt={{ mobile: 'm', tablet: '0' }}>
                      <Flex
                        alignItems="center"
                        width={{ mobile: '100%', tablet: 'auto' }}
                        justifyContent={{ mobile: 'center', tablet: 'normal' }}
                      >
                        <ArrowWrapper hasBorder onClick={handleClickBack}>
                          <LocalImage src={iconArrowLeft} />
                        </ArrowWrapper>
                        <Flex minWidth="50px" mx="m" px="s">
                          <Text color="secondaryText" fontSize="m" fontWeight="bold">
                            {activeSlide + 1} / {reviewsLength}
                          </Text>
                        </Flex>
                        <ArrowWrapper onClick={handleClickForward} bg={colors.primary}>
                          <LocalImage src={iconArrowRight} />
                        </ArrowWrapper>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </StyledSlideWrapper>
            )
          })}
        </Slider>
      </Box>
    </Flex>
  )
}

CarouselReview.propTypes = {
  reviews: PropTypes.array.isRequired,
}

export default CarouselReview
