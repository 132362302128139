import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS } from 'theme'
import { VISTA_SOCIAL_REVIEWS, CONTAINER_MAX_WIDTH } from 'consts'
import { Flex } from 'components/Layout'
import Container from 'components/Container'
import { Text } from 'components/Typography'
import CarouselReview from 'components/CarouselReview'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const CarouselReviewSection = ({ bg }) => {
  return (
    <Fragment>
      {VISTA_SOCIAL_REVIEWS.length > 0 && (
        <Wrapper bg={bg}>
          <Container
            my={{ mobile: 'l', tablet: 'xl' }}
            pl={{ mobile: 'l', tablet: 'l' }}
            pr={{ mobile: 'l', tablet: 'l' }}
            position="relative"
            maxWidth={CONTAINER_MAX_WIDTH}
            width="100%"
            height="100%"
          >
            <Flex flexDirection="column">
              <Text fontSize="l" color="secondaryText" textAlign="left">
                Testimonials
              </Text>
              <Text textAlign="left">
                <Text fontSize="3xl" as="span" fontWeight="bold" color={COLOR_CONSTANTS.SALUTE}>
                  What our
                </Text>
                <Text fontSize="3xl" as="span" fontWeight="bold" color="primary">
                  {' '}
                  Clients Say
                </Text>
              </Text>
            </Flex>

            <Container pl="0" pr="0" mt="xl" position="relative" width="100%">
              <CarouselReview reviews={VISTA_SOCIAL_REVIEWS} />
            </Container>
          </Container>
        </Wrapper>
      )}
    </Fragment>
  )
}

CarouselReviewSection.defaultProps = {
  bg: COLOR_CONSTANTS.SALT,
}

CarouselReviewSection.propTypes = {
  bg: PropTypes.string,
}
export default CarouselReviewSection
